/* eslint-disable @next/next/no-img-element */
import Marquee from 'react-fast-marquee'
import { companiesData } from '../data/companies-data/companies.jsx'

const CompaniesCarousel = () => {
  type CompanyLogos = {
    id: number
    path: string
    width?: number
    altPropm: string
    className?: string
  }

  return (
    <div className='relative w-full mb-14 mt-4'>
      {companiesData.map((item: CompanyLogos) => {
        return (
          <p key={item.id} className='sr-only'>
            {item.altPropm}
          </p>
        )
      })}

      <div className='flex flex-col space-y-2 md:space-y-4'>
        <Marquee
          gradient={true}
          speed={30}
          pauseOnHover={true}
          className=""
          gradientWidth={30}
          gradientColor={"#090909"}
        >
          {companiesData.slice(0, 17).map((item: CompanyLogos) => {
            return (
              <div key={item.id} className='w-[130px] md:w-[160px] h-[80px] flex items-center justify-center bg-white/5 p-3 md:p-4 mx-2 rounded'>
                <img
                  src={item.path}
                  alt={item.altPropm}
                  className={item.className}
                />
              </div>
            )
          })}
        </Marquee>
        <Marquee
          gradient={true}
          direction={'right'}
          gradientWidth={30}
          speed={20}
          pauseOnHover={true}
          className=""
          gradientColor={"#090909"}
        >
          {companiesData.slice(17, 34).map((item: CompanyLogos) => {
            return (
              <div key={item.id} className='w-[130px] md:w-[160px] h-[80px] flex items-center justify-center bg-white/5 p-3 md:p-4 mx-2 rounded'>
                <img
                  src={item.path}
                  alt={item.altPropm}
                  className={item.className}
                />
              </div>
            )
          })}
        </Marquee>

        <Marquee
          gradient={true}
          gradientWidth={30}
          pauseOnHover={true}
          speed={30}
          className=""
          gradientColor={"#090909"}
        >
          {companiesData.slice(34, 50).map((item: CompanyLogos) => {
            return (
              <div key={item.id} className='w-[130px] md:w-[160px] h-[80px] flex items-center justify-center bg-white/5 p-3 md:p-4 mx-2 rounded'>
                <img
                  src={item.path}
                  alt={item.altPropm}
                  className={item.className}
                />
              </div>
            )
          })}
        </Marquee>


        <Marquee
          gradient={true}
          direction={'right'}
          gradientWidth={30}
          speed={20}
          pauseOnHover={true}
          className=""
          gradientColor={"#090909"}
        >
          {companiesData.slice(49).map((item: CompanyLogos) => {
            return (
              <div key={item.id} className='w-[130px] md:w-[160px] h-[80px] flex items-center justify-center bg-white/5 p-3 md:p-4 mx-2 rounded'>
                <img
                  src={item.path}
                  alt={item.altPropm}
                  className={item.className}
                />
              </div>
            )
          })}
        </Marquee>
      </div>
    </div>
  )
}

export default CompaniesCarousel
