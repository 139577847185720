const style = 'max-h-6 md:max-h-12'

export const companiesData = [
  {
    id: 1,
    path: '/assets/company-logos/Company 1.webp',
    altPropm: ' Arta Tekstil',
    className: 'max-h-6 md:max-h-9',
  },
  {
    id: 2,
    path: '/assets/company-logos/Company 2.webp',
    altPropm: '4 Renk Tekstil',
    className: 'max-h-6 md:max-h-9',
  },
  {
    id: 3,
    path: '/assets/company-logos/Company 3.webp',
    altPropm: 'Akbaşlar Tekstil',
    className: style,
  },
  {
    id: 4,
    path: '/assets/company-logos/Company 4.webp',
    altPropm: 'Akel Tekstil',
    className: 'max-h-3 md:max-h-5',
  },
  {
    id: 5,
    path: '/assets/company-logos/Company 5.webp',
    altPropm: 'Akteks Textile',
    className: style,
  },
  {
    id: 6,
    path: '/assets/company-logos/Company 6.webp',
    altPropm: 'Almodo',
    className: style
  },
  {
    id: 7,
    path: '/assets/company-logos/Company 7.webp',
    altPropm: 'Avrupa Pasifik',
    className: style
  },
  {
    id: 8,
    path: '/assets/company-logos/Company 8.webp',
    altPropm: 'Barutçu Tekstil',
    className: style
  },
  {
    id: 9,
    path: '/assets/company-logos/Company 9.webp',
    altPropm: 'BRN Tekstil',
    className: 'max-h-6 md:max-h-9',
  },

  {
    id: 10,
    path: '/assets/company-logos/Company 10.webp',
    altPropm: 'Çahan',
    className: style,
  },
  {
    id: 11,
    path: '/assets/company-logos/Company 11.webp',
    altPropm: 'Canel Tekstil',
    className: style,
  },
  {
    id: 13,
    path: '/assets/company-logos/Company 13.webp',
    altPropm: 'Drita Tekstil',
    className: 'max-h-6 md:max-h-9',
  },
  {
    id: 14,
    path: '/assets/company-logos/Company 14.webp',
    altPropm: 'Elissa Stampa',
    className: style,
  },
  {
    id: 15,
    path: '/assets/company-logos/Company 15.webp',
    altPropm: 'Emin Tekstil Boya Apre',
    className: style,
  },
  {
    id: 16,
    path: '/assets/company-logos/Company 16.webp',
    altPropm: 'Eslem Dijital Tekstil',
    className: style,
  },
  {
    id: 17,
    path: '/assets/company-logos/Company 17.webp',
    altPropm: 'Etico Print & Fabric',
    className: 'max-h-6 md:max-h-10',
  },
  {
    id: 18,
    path: '/assets/company-logos/Company 18.webp',
    altPropm: 'Evimteks',
    className: style
  },
  {
    id: 19,
    path: '/assets/company-logos/Company 19.webp',
    altPropm: 'Forte Textile',
    className: 'max-h-6 md:max-h-10',
  },
  {
    id: 20,
    path: '/assets/company-logos/Company 20.webp',
    altPropm: 'Forza',
    className: 'max-h-6 md:max-h-10',
  },
  {
    id: 21,
    path: '/assets/company-logos/Company 21.webp',
    altPropm: 'Fratelli Digital Textile Design',
    className: style,
  },
  {
    id: 22,
    path: '/assets/company-logos/Company 22.webp',
    altPropm: 'Gözde Boya Baskı Apre ',
    className: style,
  },
  {
    id: 23,
    path: '/assets/company-logos/Company 23.webp',
    altPropm: 'Gülcemal Tekstil',
    className: 'max-h-6 md:max-h-10',
  },
  {
    id: 24,
    path: '/assets/company-logos/Company 24.webp',
    altPropm: 'Gülle Tekstil',
    className: 'max-h-6 md:max-h-10',
  },
  {
    id: 25,
    path: '/assets/company-logos/Company 25.webp',
    altPropm: 'Hamzagil',
    className: style,
  },
  {
    id: 26,
    path: '/assets/company-logos/Company 26.webp',
    altPropm: 'Harput Tekstil',
    className: style,
  },
  {
    id: 27,
    path: '/assets/company-logos/Company 27.webp',
    altPropm: 'HMK Textile',
    className: style,
  },
  {
    id: 28,
    path: '/assets/company-logos/Company 28.webp',
    altPropm: 'Inteks Tekstil ',
    className: 'max-h-6 md:max-h-10',
  },
  {
    id: 29,
    path: '/assets/company-logos/Company 29.webp',
    altPropm: 'Kadri Uğur Printing - Dyeing',
    className: style,
  },
  {
    id: 30,
    path: '/assets/company-logos/Company 30.webp',
    altPropm: 'Kasar & Dual ',
    className: style,
  },
  {
    id: 31,
    path: '/assets/company-logos/Company 31.webp',
    altPropm: 'Lami Tekstil ',
    className: style,
  },
  {
    id: 32,
    path: '/assets/company-logos/Company 32.webp',
    altPropm: 'Mem Tekstil',
    className: style,
  },
  {
    id: 33,
    path: '/assets/company-logos/Company 33.webp',
    altPropm: 'Merboy Tekstil',
    className: style,
  },
  {
    id: 34,
    path: '/assets/company-logos/Company 34.webp',
    altPropm: 'Mina Transfer',
    className: style,
  },
  {
    id: 36,
    path: '/assets/company-logos/Company 36.webp',
    altPropm: 'Newera Tekstil',
    className: style,
  },
  {
    id: 37,
    path: '/assets/company-logos/Company 37.webp',
    altPropm: 'Nurel Tekstil ',
    className: 'max-h-6 md:max-h-10',
  },
  {
    id: 38,
    path: '/assets/company-logos/Company 38.webp',
    altPropm: 'Ordu Tekstil ',
    className: style,
  },
  {
    id: 39,
    path: '/assets/company-logos/Company 39.webp',
    altPropm: 'Pentex',
    className: 'max-h-6 md:max-h-10',
  },
  {
    id: 40,
    path: '/assets/company-logos/Company 40.webp',
    altPropm: 'Pronto Digital Textile',
    className: 'max-h-6 md:max-h-10',
  },
  {
    id: 41,
    path: '/assets/company-logos/Company 41.webp',
    altPropm: 'Rabek Fashion Printing',
    className: style,
  },
  {
    id: 42,
    path: '/assets/company-logos/Company 42.webp',
    altPropm: 'Sanko',
    className: style,
  },
  {
    id: 43,
    path: '/assets/company-logos/Company 43.webp',
    altPropm: 'Sezginler Boya ve Apre',
    className: style,
  },
  {
    id: 44,
    path: '/assets/company-logos/Company 44.webp',
    altPropm: 'Sorkunlu Tekstil',
    className: style,
  },
  {
    id: 45,
    path: '/assets/company-logos/Company 45.webp',
    altPropm: 'Tilla İpek',
    className: style,
  },
  {
    id: 46,
    path: '/assets/company-logos/Company 46.webp',
    altPropm: 'Toraman Tekstil',
    className: style
  },
  {
    id: 47,
    path: '/assets/company-logos/Company 47.webp',
    altPropm: 'Universal Tekstil',
    className: style
  },
  {
    id: 48,
    path: '/assets/company-logos/Company 48.webp',
    altPropm: 'Ünteks Group',
    className: style,
  },
  {
    id: 49,
    path: '/assets/company-logos/Company 49.webp',
    altPropm: 'Vanelli textile',
    className: style
  },
  {
    id: 50,
    path: '/assets/company-logos/Company 50.webp',
    altPropm: 'Tanrıverdi Tekstil ',
    className: style,
  },
  {
    id: 51,
    path: '/assets/company-logos/Company 51.webp',
    altPropm: 'We Teks',
    className: 'max-h-6 md:max-h-10',
  },
  {
    id: 52,
    path: '/assets/company-logos/elyaf.webp',
    altPropm: 'Eylaf Tekstil',
    className: style
  },
  {
    id: 52,
    path: '/assets/company-logos/ersat.webp',
    altPropm: 'Erşat Tekstil',
    className: style
  },
  {
    id: 52,
    path: '/assets/company-logos/ipeker.webp',
    altPropm: 'İpeker',
    className: style
  },
  {
    id: 52,
    path: '/assets/company-logos/katipler.webp',
    altPropm: 'Katipler Tekstil',
    className: style
  },
  {
    id: 52,
    path: '/assets/company-logos/koyuncu.webp',
    altPropm: 'Koyuncu Tekstil',
    className: style
  },
  {
    id: 52,
    path: '/assets/company-logos/locella.webp',
    altPropm: 'Locella Tekstil',
    className: style
  },
  {
    id: 52,
    path: '/assets/company-logos/savcan.webp',
    altPropm: 'Savcan Tekstil',
    className: style
  },
  {
    id: 52,
    path: '/assets/company-logos/tafeks.webp',
    altPropm: 'Tafeks',
    className: style
  },
  {
    id: 52,
    path: '/assets/company-logos/unlu-logo.webp',
    altPropm: 'Ünlü - HiFabrik',
    className: style
  }
]
